import { get, map } from "lodash";
import { useState } from "react";
import { ToggleSwitch } from "../../components/custom-web-controls/ToggleSwitch";
import { GETALL_WORKFLOW_API } from "../../constants/tms";
import {
  GetAllWorkflowResponseDto,
  WorkflowDto,
} from "../../lib/data-transfer-object/workflow.dto";
import { useFetch } from "../../lib/hooks/useFetch";
import { TemplateTaskInformation } from "../../components/creators-hub/modals/TemplateTaskInformation";
import { SimulateWorkflow } from "../../lib/data-transfer-object/simulate.report.template.dto";
import { WorkflowStatus } from "../../constants/enum";
import dayjs from "dayjs";
import { AssignWorkflowDto } from "../../lib/data-transfer-object/assignWorkflow.dto";
import { Link } from "react-router-dom";

const Filter = () => {
  return (
    <div className="row border rounded pb-3 pt-4 m-1 mb-5">
      <div className="col-md-8 col-sm-8">
        <ToggleSwitch
          id="sort-switcher"
          name="sort-switcher"
          label="Sort by"
          value_on="Asc"
          value_off="Desc"
          value={true}
        />
        <div className="btn-group" role="group" aria-label="Basic example">
          <button
            type="button"
            className="btn btn-outline-primary btn-fw btn-xs active"
          >
            <i className="fa-solid fa-envelope-open-text mr-1"></i> Title
          </button>
          <button
            type="button"
            className="btn btn-outline-primary btn-fw btn-xs"
          >
            <i className="far fa-calendar-plus mr-1"></i> Created at
          </button>
          <button
            type="button"
            className="btn btn-outline-primary btn-fw btn-xs"
          >
            <i className="far fa-calendar-check mr-1"> </i> Updated at
          </button>
        </div>
      </div>
      <div className="col-md-4 col-sm-4">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Search for..."
            aria-label="Search for..."
          />
          <div className="input-group-append">
            <button className="btn btn-sm btn-primary" type="button">
              <i className="icon-search"></i>
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AssignWorkflow = () => {
  const simulateInitialValue = {
    id: 0,
    workflowName: "",
    status: WorkflowStatus.ACTIVE,
    workflowConfig: {
      isCommentRequired: false,
      isFileUploadRequired: false,
      isApproverRequired: false,
    },
    workflowApprover: "",
    workflowAssignTo: "",
    workflowDueDate: dayjs().format("YYYY-MM-DD"),
    actionItems: [],
  };

  const assignWorkflowInitialValue: AssignWorkflowDto = {
    assignTo: "",
    monitors: "",
    assignedBy: "",
    approver: "",
    assignmentType: 1,
    completionMode: 1,
    dueDate: dayjs().format("YYYY-MM-DD"),
    compete: 0,
    alert: 0,
    workflowId: 0,
    actionItems: [],
  };

  const { response, fetchErrors, loaded } =
    useFetch<GetAllWorkflowResponseDto>(GETALL_WORKFLOW_API);
  const [showWorkflowInfo, setShowWorkflowInfo] = useState<boolean>(false);
  const [showAssignWorkflowModal, setShowAssignWorkflowModal] =
    useState<boolean>(false);
  const [assignWorkflowData, setAssignWorkflowData] =
    useState<AssignWorkflowDto>(assignWorkflowInitialValue);
  const [simulateWorkflowData, setSimulateWorkflowData] =
    useState<SimulateWorkflow>(simulateInitialValue);

  const onSimulate = (workflow: WorkflowDto) => {
    console.log(workflow);
    setShowWorkflowInfo(!showWorkflowInfo);
    setSimulateWorkflowData({
      ...simulateInitialValue,
      id: workflow.workflowId,
      workflowName: workflow.workflowName,
      workflowConfig: workflow.workflowConfig,
      actionItems: map(workflow.actionItems, (actionItem) => {
        return {
          actionItemId: actionItem.actionItemId,
          actionItemName: actionItem.actionItemName,
          actionItemDescription: actionItem.actionItemDescription,
          actionItemApprover: "",
          actionItemAssignTo: "",
          actionItemDueDate: dayjs().format("YYYY-MM-DD"),
          status: WorkflowStatus.ACTIVE,
        };
      }),
    });
  };

  const populateWorkflows = () => {
    const workflows = get(response, "workflows");
    console.log(workflows);

    return map(workflows, (workflow, index) => {
      const { subCategoryName, workflowName } = workflow;
      return (
        <tr key={index}>
          <td>{"Senior Care"}</td>
          <td>{subCategoryName}</td>
          <td>{workflowName}</td>
          <td className="py-0 px-5">
            <button type="button" className="btn btn-primary btn-sm text-left">
              <i className="fa-solid fa-arrow-up-right-from-square mr-2"></i>{" "}
              View Workflow
            </button>
            <Link to={`/assignworkflow/${workflow.workflowId}`}>
              <button
                type="button"
                className="btn btn-success btn-sm text-left"
              >
                <i className="fa-solid fa-circle-check mr-2"></i> Assign
                Workflow
              </button>
            </Link>

            <button
              type="button"
              className="btn btn-danger btn-sm text-left"
              data-toggle="modal"
              data-target="#modal-fullscreen-xl-generate-report-action"
              onClick={() => onSimulate(workflow)}
            >
              <i className="fa fa-vial mr-2"></i> Simulate
            </button>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body pb-5">
                <p className="card-title">
                  <i className="fa-solid fa-arrows-turn-to-dots mr-2"></i>
                  Assign Workflow
                </p>
                <div className="my-3">
                  <hr />
                </div>

                <Filter />

                <table
                  className="table table-striped table-bordered"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th className="col-3">Category</th>
                      <th className="col-3">Sub Category</th>
                      <th className="col-3">Name</th>
                      <th className="col-3">Options</th>
                    </tr>
                  </thead>
                  <tbody>{loaded && populateWorkflows()}</tbody>
                </table>
                {/* Convert this to pagination */}
                <nav aria-label="Page navigation example" className="mt-5">
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <a className="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showWorkflowInfo && (
        <TemplateTaskInformation
          simulateWorkflow={simulateWorkflowData}
          onCompleteOrSentForApproval={() => console.log()}
          onActionItemComplete={() => console.log()}
          onActionItemSkip={() => console.log()}
        />
      )}
    </>
  );
};
