import { forEach, isEmpty, map } from "lodash";
import React, { useEffect, useState, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import * as XLSX from "xlsx";
import {
  addSelectedSheets,
  removeInSelectedSheets,
} from "../../features/importerConfigSlice";
import axios from "axios";

interface ImportSettingsProps {
  source: string;
}

export const ImportSettings = (props: ImportSettingsProps) => {
  const { source } = props;
  const [sheets, setSheets] = useState<string[]>();

  const api = axios.create({
    baseURL: `https://dev-us01-backend-api.azurewebsites.net/api/Importer/${
      source === "TemplateTask" ? "Workflows" : "Categories"
    }`,
  });

  const dispatch = useDispatch();

  const importerConfig = useSelector(
    (state: RootState) => state.importerConfig
  );

  const handleSheetCheck = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      dispatch(addSelectedSheets(e.target.value));
    } else {
      // Improve this
      //dispatch(removeInSelectedSheets(1));
    }
  };

  const handleImport = async () => {
    // Add more validation
    if (importerConfig.uploadedFile) {
      const reader = new FileReader();
      let jsonData: unknown[] = [];

      reader.readAsArrayBuffer(importerConfig.uploadedFile);
      reader.onload = async (e) => {
        const bufferArray = e.target?.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        // Missing validataion
        // Combine sheets

        forEach(importerConfig.selectedSheets, (sheet) => {
          const ws = wb.Sheets[sheet];
          jsonData = XLSX.utils.sheet_to_json(ws);
        });

        console.log(jsonData);

        // Post API
        let data;
        if (source === "TemplateTask") {
          data = JSON.stringify({
            workflowFileSchemaDtos: jsonData,
          });
        } else {
          data = JSON.stringify({
            categoryFileSchemaDtos: jsonData,
          });
        }

        const result = await api.post("/", data, {
          headers: { "Content-Type": "application/json" },
        });

        console.log(result);
      };
    }
  };

  const populateSheets = () => {
    return map(sheets, (sheet) => {
      return (
        <label className="checkbox-inline">
          <input
            type="checkbox"
            value={sheet}
            onChange={(e) => handleSheetCheck(e)}
          />
          <span className="badge">{sheet}</span>
        </label>
      );
    });
  };

  const readUploadedFile = () => {
    //Possible move this to a handler file

    if (importerConfig.uploadedFile) {
      const reader = new FileReader();

      reader.readAsArrayBuffer(importerConfig.uploadedFile);
      reader.onload = (e) => {
        const bufferArray = e.target?.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wbSheets = wb.SheetNames;

        setSheets(wbSheets);
      };
    }
  };

  useEffect(() => {
    readUploadedFile();
  }, [importerConfig]);

  return (
    <div className="wrapper wrapper-content animated fadeInRight">
      <div className="form-group row mb-0">
        <label
          htmlFor="inputPassword"
          className="col-sm-3 col-form-label text-right"
        >
          Client
        </label>
        <div className="col-sm-6">
          <select id="status" className="form-control" required>
            <option disabled selected></option>
            <option value="Client 1">Client 1</option>
            <option value="Client 2">Client 2</option>
            <option value="Client 3">Client 3</option>
            <option value="Client 4">Client 4</option>
            <option value="Client 5">Client 5</option>
          </select>
        </div>
      </div>

      <div className="form-group row mb-0">
        <label
          htmlFor="inputPassword"
          className="col-sm-3 col-form-label text-right"
        >
          Sheets
        </label>
        <div className="col-sm-6">
          <div className="form-group badge-checkboxes mt-3">
            <div>{!isEmpty(sheets) ? populateSheets() : null}</div>
          </div>
        </div>
      </div>

      <div className="form-group row mb-0 border-top pt-4">
        <div className="offset-3 pl-3">
          <button type="button" className="btn btn-outline-dark btn-icon-text">
            <i className="fa-solid fa-spell-check mr-2"></i> Validate
          </button>

          <button
            type="button"
            className="btn btn-primary btn-icon-text ml-3"
            onClick={handleImport}
          >
            <i className="fa fa-upload mr-2"></i> Import
          </button>
        </div>
      </div>
    </div>
  );
};
