import { map } from "lodash";
import { useState } from "react";
import { useDropzone } from "react-dropzone";

interface UploadReferenceMaterialProps {
  canUploadFile: boolean;
  onFileUpload(fileName: string): void;
  includeDownload: boolean;
  includeRemove: boolean;
}

export const UploadReferenceMaterial = (
  props: UploadReferenceMaterialProps
) => {
  const { canUploadFile, onFileUpload, includeDownload, includeRemove } = props;

  const [files, setFiles] = useState<File[]>([]);

  const { getRootProps, acceptedFiles } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => {
        setFiles([...files, file]);
        //dispatch(setUploadedFile(file));
        onFileUpload(file.name);
      });
    },
  });

  const showUploadedFiles = () => {
    return (
      <>
        {map(files, (file, index) => {
          return (
            <div key={index} className="col-12 border-bottom py-2 d-flex">
              <span className="mr-auto">
                <i className="fa-solid fa-file-pdf mr-2 py-2"></i> {file.name}
              </span>
              <button
                type="button"
                className="btn btn-primary btn-icon-text btn-sm ml-2"
              >
                <i className="fa-solid fa-file-export"></i> Preview
              </button>
              {includeRemove && (
                <button
                  type="button"
                  className="btn btn-danger btn-icon-text btn-sm ml-2"
                >
                  <i className="fa-solid fa-trash-can"></i> Remove
                </button>
              )}

              {includeDownload && (
                <button
                  type="button"
                  className="btn btn-success btn-icon-text btn-sm ml-2"
                >
                  <i className="fa-solid fa-file-arrow-down"></i> Download
                </button>
              )}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      {canUploadFile ? (
        <>
          <section>
            <div {...getRootProps({ className: "dropzone" })}>
              <form className="needsclick">
                <div className="dz-message needsclick">
                  {/** TODO: UI for the files */}
                  <i className="fa-solid fa-cloud-arrow-up fa-5x d-block mt-5"></i>
                  {"Drop files here or click to upload."}
                  <br />
                </div>
              </form>
            </div>
          </section>
          <hr className="mx-5" /> : null
        </>
      ) : null}

      {showUploadedFiles()}
    </>
  );
};
