import dayjs from "dayjs";
import { AssignWorkflowDto } from "../lib/data-transfer-object/assignWorkflow.dto";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ActionItemDto } from "../lib/data-transfer-object/actionItem.dto";

const initialState: AssignWorkflowDto = {
  assignTo: "",
  monitors: "",
  assignedBy: '',
  approver: "",
  assignmentType: 1,
  completionMode: 1,
  dueDate: dayjs().format("YYYY-MM-DD"),
  compete: 0,
  alert: 0,
  workflowId: 0,
  actionItems: [],
};

export const assignWorkflowSlice = createSlice({
  name: 'assignWorkflowSlice',
  initialState,
  reducers: {
    changeWorkflowId: (state, action: PayloadAction<number>) => {
      state.workflowId = action.payload;
    },
    changeActionItems: (state, action: PayloadAction<ActionItemDto[]>) => {  
      state.actionItems = action.payload;
    },
    changeAssignTo: (state, action: PayloadAction<string>) => {
      state.assignTo = action.payload;
    },
    changeMonitors: (state, action: PayloadAction<string>) => {
      state.monitors = action.payload;
    },
    changeApprover: (state, action: PayloadAction<string>) => {
      state.approver = action.payload;
    },
    changeAssignmentType: (state, action: PayloadAction<number>) => {
      state.assignmentType = action.payload;
    },
    changeCompletionMode: (state, action: PayloadAction<number>) => {
      state.completionMode = action.payload;
    },
    changeDueDate: (state, action: PayloadAction<string>) => {
      state.dueDate = action.payload;
    },
    changeCompeteDays: (state, action: PayloadAction<number>) => {
      state.compete = action.payload;
    },
    changeAlertDays: (state, action: PayloadAction<number>) => {
      state.alert = action.payload;
    },
    resetAssignWorkflow:  () => initialState  
  }
});

export const { changeAssignTo, changeMonitors, changeApprover, changeAssignmentType, 
  changeCompeteDays, changeCompletionMode, changeAlertDays, changeDueDate, changeWorkflowId, changeActionItems, resetAssignWorkflow } = assignWorkflowSlice.actions;

export default assignWorkflowSlice.reducer;