import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeApprover,
  changeAssignTo,
  changeAssignmentType,
  changeMonitors,
} from "../../features/assignWorkflowSlice";
import { AssignmentType, AssignedWorkflowStatus } from "../../constants/enum";
import { RootState } from "../../app/store";
import { isUndefined } from "lodash";

interface AssignmentInformationProps {
  isReadOnly: boolean;
  data?: AssignmentInfoDto;
}

type AssignmentInfoDto = {
  assignTo: string; //string[];
  monitors: string; //string[];
  assignBy: string;
  approver: string;
  assignmentType: AssignmentType;
};

const initialValue = {
  assignTo: "",
  monitors: "",
  assignBy: "",
  approver: "",
  assignmentType: AssignmentType.INDIVIDUAL,
};

export const AssignmentInformation = (props: AssignmentInformationProps) => {
  const { isReadOnly, data } = props;
  const dispatch = useDispatch();
  const profile = useSelector((state: RootState) => state.profile);
  const [assignmentInfoValue, setAssignmentInfoValue] =
    useState<AssignmentInfoDto>(initialValue);

  const onApproverChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeApprover(event.target.value));
  };

  const onAssignmentTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "1") {
      setAssignmentInfoValue({
        ...assignmentInfoValue,
        assignmentType: AssignmentType.INDIVIDUAL,
      });
      dispatch(changeAssignmentType(AssignmentType.INDIVIDUAL));
    } else {
      setAssignmentInfoValue({
        ...assignmentInfoValue,
        assignmentType: AssignmentType.SHARED,
      });
      dispatch(changeAssignmentType(AssignmentType.SHARED));
    }
  };

  const onAssignToOrMonitorChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAssignmentInfoValue({
      ...assignmentInfoValue,
      [event.target.id]: event.target.value,
    });

    if (event.target.id === "assignTo")
      dispatch(changeAssignTo(event.target.value));
    else dispatch(changeMonitors(event.target.value));
  };

  useEffect(() => {
    data && setAssignmentInfoValue(data);
  }, [data]);

  useEffect(() => {
    isUndefined(data) &&
      setAssignmentInfoValue({
        ...assignmentInfoValue,
        assignBy: profile.email,
      });
  }, [data]);

  return (
    <div id="assingment-information">
      <p className="card-title m-0 mb-2">Assignment Information</p>
      <div className="card bg-light border mb-5">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-sm-3">
              <p className="m-0">
                <b className="text-primary mr-2">Assign to:</b>
                <div className="form-group mb-2 row">
                  <div className="col-sm-12">
                    {!isReadOnly ? (
                      <>
                        <input
                          type="text"
                          data-role="tagsinput"
                          id="assignTo"
                          className="form-control"
                          value={assignmentInfoValue.assignTo}
                          onChange={(e) => onAssignToOrMonitorChange(e)}
                        />
                        <small className="form-text text-muted">
                          Use comma , to separate Names from each other.
                        </small>
                      </>
                    ) : (
                      <input
                        type="text"
                        id="assignTo"
                        className="creators-hub-simulate-modal-input-text w-100 mt-4"
                        disabled
                        value={assignmentInfoValue.assignTo}
                      />
                    )}
                  </div>
                </div>
              </p>
            </div>
            <div className="col-12 col-sm-3">
              <p className="m-0">
                <b className="text-primary mr-2">Monitors:</b>
                <div className="form-group mb-2 row">
                  <div className="col-sm-12">
                    {!isReadOnly ? (
                      <>
                        <input
                          type="text"
                          data-role="tagsinput"
                          id="monitors"
                          className="form-control"
                          onChange={(e) => onAssignToOrMonitorChange(e)}
                        />
                        <small className="form-text text-muted">
                          Use comma , to separate Names from each other.
                        </small>
                      </>
                    ) : (
                      <input
                        type="text"
                        id="assignTo"
                        className="creators-hub-simulate-modal-input-text w-100 mt-4"
                        disabled
                        value={assignmentInfoValue.monitors}
                      />
                    )}
                  </div>
                </div>
              </p>
            </div>
            <div className="col-12 col-sm-3">
              <p className="m-0">
                <b className="text-primary mr-2">Assigned by:</b>
                <div className="form-group mb-2 row">
                  <div className="col-sm-12">
                    <input
                      name="assigned-by"
                      id="assigned-by"
                      value={assignmentInfoValue.assignBy}
                      className="creators-hub-simulate-modal-input-text w-100 mt-4"
                      disabled
                    />
                  </div>
                </div>
              </p>
            </div>
            <div className="col-12 col-sm-3">
              <p className="m-0">
                <b className="text-primary mr-2">Approver:</b>
                <div className="form-group mb-2 row">
                  <div className="col-sm-12">
                    {!isReadOnly ? (
                      <>
                        <input
                          list="assigned-bys"
                          name="assigned-by"
                          id="assigned-by"
                          className="creators-hub-simulate-modal-input-text w-100 mt-4"
                          onChange={(e) => onApproverChange(e)}
                        />
                        <datalist id="assigned-bys">
                          <option value="John Doe" />
                          <option value="John Doe Approver" />
                        </datalist>
                      </>
                    ) : (
                      <input
                        name="approver"
                        id="approver"
                        value={assignmentInfoValue.approver}
                        className="creators-hub-simulate-modal-input-text w-100 mt-4"
                        disabled
                      />
                    )}
                  </div>
                </div>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12">
              <div className="form-inline float-right">
                <b className="text-primary mr-3">Assignmnet Type:</b>
                <div className="form-check mr-5">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="individual-task"
                      id="individual-task"
                      disabled={isReadOnly}
                      value={AssignmentType.INDIVIDUAL}
                      checked={
                        assignmentInfoValue.assignmentType ===
                        AssignmentType.INDIVIDUAL
                      }
                      onChange={(e) => onAssignmentTypeChange(e)}
                    />
                    Individual Task
                    <i className="input-helper"></i>
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="shared-task"
                      id="shared-task"
                      disabled={isReadOnly}
                      value={AssignmentType.SHARED}
                      checked={
                        assignmentInfoValue.assignmentType ===
                        AssignmentType.SHARED
                      }
                      onChange={(e) => onAssignmentTypeChange(e)}
                    />
                    Shared Task
                    <i className="input-helper"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
