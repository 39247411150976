import { useState } from "react";
import { UploadReferenceMaterial } from "./UploadReferenceMaterial";
import { UploadedFiles } from "./UploadedFiles";
import { AssignedWorkflowStatus } from "../../constants/enum";

interface ReferenceMaterialsProps {
  noOfMaterials: number;
  status: AssignedWorkflowStatus;
}

export const ReferenceMaterials = (props: ReferenceMaterialsProps) => {
  const { noOfMaterials, status } = props;
  const [materialsCount, setMaterialsCount] = useState<number>(noOfMaterials);

  const onReferenceUpload = () => {
    setMaterialsCount(materialsCount + 1);
  };

  return (
    <div id="reference-materials">
      <p className="card-title m-0 mb-2">Refference Materials</p>
      <ul className="nav nav-tabs" id="setting-panel" role="tablist">
        <li className="nav-item">
          <a
            className="nav-link active"
            id="refference-materials-tab"
            data-toggle="tab"
            href="#refference-materials-section"
            role="tab"
            aria-controls="refference-materials-section"
            aria-expanded="true"
          >
            Refference Materials{" "}
            <span className="badge badge-light ml-3">{materialsCount}</span>
          </a>
        </li>
      </ul>
      {status === AssignedWorkflowStatus.NEW ? (
        <div className="tab-content" id="setting-content">
          <div
            className="tab-pane fade show active scroll-wrapper"
            id="refference-materials-section"
            role="tabpanel"
            aria-labelledby="refference-materials-section"
          >
            <div className="wrapper wrapper-content animated fadeInRight">
              <div
                className="accordion collapase-card"
                id="approvalFileUploadAccordion"
              >
                <div className="card bg-light border">
                  <div className="card-header" id="approvalFileUpload">
                    <a
                      href="#"
                      className="btn btn-header-link text-dark"
                      data-toggle="collapse"
                      data-target="#approvalFileUploadSection"
                      aria-expanded="true"
                      aria-controls="approvalFileUploadSection"
                    >
                      Document Name
                    </a>
                  </div>
                  <div
                    id="approvalFileUploadSection"
                    className="collapse"
                    aria-labelledby="approvalFileUpload"
                    data-parent="#approvalFileUploadAccordion"
                  >
                    <div className="card-body pt-4">
                      <UploadReferenceMaterial
                        canUploadFile={false}
                        onFileUpload={onReferenceUpload}
                        includeDownload={true}
                        includeRemove={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <UploadedFiles />
      )}
      <div className="mb-5 border-bottom"></div>
    </div>
  );
};
