import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { useIsAuthenticated, useSignIn } from "react-auth-kit";
import { get } from "lodash";
import { LOGIN_URL } from "../constants/api-url";
import { StatusCode } from "../lib/common/error";

type LoginDto = {
  email: string;
  password: string;
};

const intialValue: LoginDto = {
  email: "",
  password: "",
};

export const Login = () => {
  const [loginDto, setLoginDto] = useState<LoginDto>(intialValue);
  const navigate = useNavigate();
  const singIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();

  const onSignIn = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    try {
      const response = await axios
        .post(
          LOGIN_URL,
          JSON.stringify({
            username: loginDto.email,
            password: loginDto.password,
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: false,
          }
        )
        .catch((err) => {});

      if (get(response, "status") === StatusCode.SuccessOK) {
        const data = get(response, "data");
        const accessToken = get(data, "accessToken");
        const expiresIn = get(data, "expiresIn");
        const refreshToken = get(data, "refreshToken");
        const fullName = get(data, "fullName");
        if (
          singIn({
            token: accessToken,
            expiresIn: expiresIn,
            tokenType: "Bearer",
            authState: { email: loginDto.email, name: fullName },
            refreshToken: refreshToken,
          })
        ) {
          setTimeout(() => navigate("/", { replace: true }));
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };
  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <a href="#" className="navbar-brand">
              <img src="images/logo.png" alt="logo" />
            </a>
          </div>
        </nav>
      </header>

      <div className="container">
        <div className="row py-5 mt-4 align-items-center">
          <div className="col-md-5 pr-lg-5 mb-5 mb-md-0">
            <img
              src="images/login-bg.png"
              alt=""
              className="img-fluid mb-3 d-none d-md-block"
            />
            <div
              style={{
                position: "absolute",
                zIndex: -9999,
                top: " -1000000px",
              }}
            >
              <a href="https://www.freepik.com/free-vector/log-into-several-devices-responsive-app-design-wifi-zone-gadgets-online-communication-social-networking-web-connection-initialize-sign-up-vector-isolated-concept-metaphor-illustration_12470224.htm?query=login">
                Image by vectorjuice
              </a>{" "}
              on Freepik
            </div>
          </div>
          <div className="col-md-7 col-lg-5 ml-auto">
            <h3 className="font-weight-bold mb-5">SIGN IN</h3>
            <form action="#">
              <div className="row">
                <div className="input-group col-lg-12 mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-white px-4 border-md border-right-0">
                      <i className="fa fa-envelope text-muted"></i>
                    </span>
                  </div>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    className="form-control bg-white border-left-0 border-md"
                    onChange={(e) =>
                      setLoginDto({ ...loginDto, email: e.target.value })
                    }
                  />
                </div>

                <div
                  className="input-group col-lg-12 mb-4"
                  id="show_hide_password_login"
                >
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-white px-4 border-md border-right-0">
                      <i className="fa fa-lock text-muted"></i>
                    </span>
                  </div>
                  <input
                    id="password"
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="form-control bg-white border-left-0 border-md"
                    onChange={(e) =>
                      setLoginDto({ ...loginDto, password: e.target.value })
                    }
                  />
                  <div className="input-group-append">
                    <span className="input-group-text bg-white px-4 border-md">
                      <a href="">
                        <i className="fa fa-eye-slash" aria-hidden="true"></i>
                      </a>
                    </span>
                  </div>
                </div>
                <div className="form-group col-lg-12 mx-auto mb-0">
                  <button
                    type="button"
                    className="btn btn-primary btn-block py-3"
                    onClick={(e) => onSignIn(e)}
                  >
                    <span className="font-weight-bold">
                      <i className="fa-solid fa-right-to-bracket pr-2"></i> Sign
                      in to your account
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
