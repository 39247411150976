import { Link, useParams } from "react-router-dom";
import { useFetch } from "../../lib/hooks/useFetch";
import {
  COMPLETE_ASSIGNED_WORKFLOW,
  GET_ASSIGNED_WORKFLOW,
} from "../../constants/api-url";
import {
  AssignedWorkflowDto,
  GetAssignedWorkflowResponseDto,
  UpdateAssingedWorkflowRequest,
} from "../../lib/data-transfer-object/assignWorkflow.dto";
import { useEffect, useState } from "react";
import {
  AssignmentInformation,
  GeneralInformation,
  SuccessModal,
} from "../../components/shared";
import {
  AssignmentType,
  AssignedWorkflowStatus,
  AssignedWorkflowActionItemStatus,
  ActionItemActionType,
} from "../../constants/enum";
import { get, isEmpty, isUndefined, map } from "lodash";
import dayjs from "dayjs";
import { DATE_PICKER_FORMAT } from "../../constants/tms";
import { ActionItems } from "../../components/shared/ActionItems";
import { ActionItemDto } from "../../lib/data-transfer-object/actionItem.dto";
import { ReferenceMaterials } from "../../components/shared/ReferenceMaterials";
import { AddCommentNotes } from "../../components/shared/AddCommentNotes";
import { ApprovalFileUpload } from "../../components/shared/ApprovalFileUpload";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { usePost } from "../../lib/hooks/usePost";

const initialValue: AssignedWorkflowDto = {
  id: 0,
  assignTo: "",
  monitors: "",
  assignedBy: "",
  approver: "",
  status: 0,
  assignmentType: 0,
  completionMode: 0,
  dueDate: "",
  compete: 0,
  alert: 0,
  workflowId: 0,
  workflowName: "",
  workflowConfig: {
    isApproverRequired: false,
    isCommentRequired: false,
    isFileUploadRequired: false,
  },
  actionItems: [],
};

export const AssignedWorkflow = () => {
  const { id } = useParams();

  const profile = useSelector((state: RootState) => state.profile);

  const [assignedWorkflow, setAssignedWorkflow] =
    useState<AssignedWorkflowDto>(initialValue);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const { response, loaded, fetchErrors } =
    useFetch<GetAssignedWorkflowResponseDto>(
      `${GET_ASSIGNED_WORKFLOW}?assingedWorkflowId=${id}`
    );

  const completeWorkflowRequest = usePost<unknown>();

  const mapResponseToDto = () => {
    if (response) {
      const workflow = get(response, "workflow");
      const actionItems = get(workflow, "actionItems");
      const assignmentType = get(response, "assignmentType");
      const completionMode = get(response, "completionMode");

      const assignedWorkflowDto: AssignedWorkflowDto = {
        id: response.assignedWorkflowId,
        assignTo: profile.email,
        monitors: response.monitors,
        assignedBy: response.assingedBy,
        approver: response.approver,
        status: response.assignedWorflowStatus,
        assignmentType: assignmentType,
        completionMode: completionMode,
        dueDate: dayjs(response.dueDate).format(DATE_PICKER_FORMAT),
        compete: response.competeDays,
        alert: response.alertDays,
        workflowId: workflow.workflowId,
        workflowName: workflow.workflowName,
        workflowConfig: workflow.workflowConfig,
        actionItems: !isEmpty(actionItems)
          ? map(actionItems, (actionItem) => {
              return {
                ...actionItem,
                actionItemStatus: AssignedWorkflowActionItemStatus.ACTIVE,
              };
            })
          : [],
      };
      setAssignedWorkflow(assignedWorkflowDto);
    }
  };

  const onActionItemCompletedOrMarked = (
    actionItemId: number,
    action: ActionItemActionType
  ) => {
    const actionItems = [...assignedWorkflow.actionItems];
    const updatedActionItems = map(actionItems, (actionItem) => {
      return actionItem.actionItemId === actionItemId
        ? {
            ...actionItem,
            actionItemStatus:
              action === ActionItemActionType.TO_COMPLETE
                ? AssignedWorkflowActionItemStatus.COMPLETED
                : AssignedWorkflowActionItemStatus.MARKED_NOT_APPLICABLE,
          }
        : actionItem;
    });

    setAssignedWorkflow({
      ...assignedWorkflow,
      actionItems: [...updatedActionItems],
    });
  };

  const onCompleteOrSentForApproval = () => {
    console.log(assignedWorkflow.id);
    completeWorkflowRequest(COMPLETE_ASSIGNED_WORKFLOW, {
      assignedWorkflowId: assignedWorkflow.id,
    });
    setShowSuccessModal(true);
  };

  useEffect(() => {
    loaded && mapResponseToDto();
  }, [loaded, response]);

  return (
    <>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body pb-5">
                <button
                  type="button"
                  className="btn btn-primary btn-sm float-right"
                  data-toggle="modal"
                  data-target="#success-modal"
                  onClick={() => onCompleteOrSentForApproval()}
                >
                  <i className="fa-solid fa-circle-check mr-2"></i>{" "}
                  {assignedWorkflow.workflowConfig.isApproverRequired
                    ? " Sent For Approval"
                    : " Complete"}
                </button>
                <Link to="/">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm float-right mr-2"
                    onClick={() => console.log()}
                  >
                    <i className="fa-solid fa-rectangle-xmark pr-2"></i> Cancel
                  </button>
                </Link>
                <p className="card-title">
                  <i className="fa-solid fa-arrows-turn-to-dots mr-2"></i>
                  Assigned Workflow (Task)
                </p>
                <div className="my-3">
                  <hr />
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <AssignmentInformation
                        isReadOnly={true}
                        data={{
                          assignTo: assignedWorkflow.assignTo,
                          monitors: assignedWorkflow.monitors,
                          assignBy: assignedWorkflow.assignedBy,
                          approver: assignedWorkflow.approver,
                          assignmentType: assignedWorkflow.assignmentType,
                        }}
                      />
                      <GeneralInformation
                        isReadOnly={true}
                        data={{
                          dueDate: assignedWorkflow.dueDate,
                          competeDays: assignedWorkflow.compete,
                          alertDays: assignedWorkflow.alert,
                          recurrence: false,
                          completionMode: assignedWorkflow.completionMode,
                        }}
                      />
                      {!isEmpty(assignedWorkflow.actionItems) && (
                        <ActionItems
                          onActionItemCompletedOrMarked={
                            onActionItemCompletedOrMarked
                          }
                          data={assignedWorkflow.actionItems}
                          assignmentStatus={AssignedWorkflowStatus.ACTIVE}
                        />
                      )}
                      <ReferenceMaterials
                        noOfMaterials={5}
                        status={assignedWorkflow.status}
                      />
                      {assignedWorkflow.workflowConfig.isFileUploadRequired ? (
                        <ApprovalFileUpload
                          onUploadFile={() => console.log("File Upload")}
                        />
                      ) : null}
                      <AddCommentNotes />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSuccessModal ? (
        <SuccessModal message="Workflow has been completed" />
      ) : null}
    </>
  );
};
