export const UploadedFiles = () => {
  return (
    <div className="card bg-light border border-rounded mb-5">
      <div className="card-body pt-4">
        <span className="mb-5 d-block">Document Name</span>
        <div className="col-12 border-bottom py-2 d-flex">
          <span className="mr-auto">
            <i className="fa-solid fa-file-pdf mr-2 py-2"></i> File Demo1.pdf
          </span>
          <button
            type="button"
            className="btn btn-primary btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-file-export"></i> Preview
          </button>
          <button
            type="button"
            className="btn btn-danger btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-trash-can"></i> Remove
          </button>
          <button
            type="button"
            className="btn btn-success btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-file-arrow-down"></i> Download
          </button>
        </div>

        <div className="col-12 border-bottom py-2 d-flex">
          <span className="mr-auto">
            <i className="fa-solid fa-file-word mr-2 py-2"></i> File Demo 2.doc
          </span>
          <button
            type="button"
            className="btn btn-primary btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-file-export"></i> Preview
          </button>
          <button
            type="button"
            className="btn btn-danger btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-trash-can"></i> Remove
          </button>
          <button
            type="button"
            className="btn btn-success btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-file-arrow-down"></i> Download
          </button>
        </div>

        <div className="col-12 border-bottom py-2 d-flex">
          <span className="mr-auto">
            <i className="fa-solid fa-file-excel mr-2 py-2"></i> File Demo 3.xls
          </span>
          <button
            type="button"
            className="btn btn-primary btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-file-export"></i> Preview
          </button>
          <button
            type="button"
            className="btn btn-danger btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-trash-can"></i> Remove
          </button>
          <button
            type="button"
            className="btn btn-success btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-file-arrow-down"></i> Download
          </button>
        </div>

        <div className="col-12 border-bottom py-2 d-flex">
          <span className="mr-auto">
            <i className="fa-solid fa-file-powerpoint mr-2 py-2"></i> File Demo
            4.ppt
          </span>
          <button
            type="button"
            className="btn btn-primary btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-file-export"></i> Preview
          </button>
          <button
            type="button"
            className="btn btn-danger btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-trash-can"></i> Remove
          </button>
          <button
            type="button"
            className="btn btn-success btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-file-arrow-down"></i> Download
          </button>
        </div>

        <div className="col-12 border-bottom py-2 d-flex">
          <span className="mr-auto">
            <i className="fa-solid fa-file-image mr-2 py-2"></i> File Demo 5.jpg
          </span>
          <button
            type="button"
            className="btn btn-primary btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-file-export"></i> Preview
          </button>
          <button
            type="button"
            className="btn btn-danger btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-trash-can"></i> Remove
          </button>
          <button
            type="button"
            className="btn btn-success btn-icon-text btn-sm ml-2"
          >
            <i className="fa-solid fa-file-arrow-down"></i> Download
          </button>
        </div>
      </div>
    </div>
  );
};
