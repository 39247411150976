import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { ImportOptions } from "../../components/settings/ImportOptions";
import { TaskCategoryDataImporter } from "../../components/settings/TaskCategoryDataImporter";
import { TemplateTaskDataImporter } from "../../components/settings/TemplateTaskDataImporter";
import { ImporterTabs } from "../../constants/enum";

export const ImportData = () => {
  const importerConfig = useSelector(
    (state: RootState) => state.importerConfig
  );

  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col-md-3 col-12 grid-margin stretch-card">
          <ImportOptions />
        </div>

        <div className="col-md-9 col-12 grid-margin stretch-card">
          {importerConfig.activeTab === ImporterTabs.TASK_CATEGORY ? (
            <TaskCategoryDataImporter />
          ) : (
            <TemplateTaskDataImporter />
          )}
        </div>
      </div>
    </div>
  );
};
